import {
  Avatar,
  CardHeader,
  CardMedia,
  Chip,
  Grid,
  Theme,
  Tooltip,
  Typography
} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LanguageIcon from "@material-ui/icons/Language";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import StarRateIcon from "@material-ui/icons/StarRate";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Lightbulb } from "mdi-material-ui";
import * as React from "react";
import { Query } from "react-apollo";
import { HEADER_PADDING, isMobile } from "../../../../AppLayout";
import { BackButton, Loader } from "../../../../components";
import { imageUrl } from "../../../../lib";
import { BlogTile } from "../../../Blog/components";
import { recipesGQL } from "../../../Recipe/components";
import { RecipeTile } from "../../../Recipe/components/RecipeSearch/RecipeTile";
import {
  RecipesQuery,
  RecipesQueryVariables
} from "../../../Recipe/components/RecipeSearch/types/RecipesQuery";
import { FoodQuery_food } from "../../types/FoodQuery";
import { useStyles } from "./style";

interface Props {
  food: FoodQuery_food;
}

export const FoodPage: React.FC<Props> = ({ food }) => {
  const theme = useTheme<Theme>();
  const classes = useStyles(theme);

  const chipStyles = makeStyles(() => ({
    colorDefault: { color: "#fff", backgroundColor: "rgb(0, 107, 71)" },
    root: {
      width: "32px",
      height: "32px",
      fontSize: "1rem",
      marginRight: "-4px"
    }
  }))();

  const macroTypes = food.food_macros.reduce<
    { macro: string; amount: number }[]
  >((macros, foodMacro) => {
    const macro = macros.find(m => m.macro === foodMacro.macro_type.name);

    const macroValue =
      foodMacro.unit.abbrev === "mg" ? foodMacro.value / 1000 : foodMacro.value;

    if (!macro) {
      macros.push({
        macro: foodMacro.macro_type.name,
        amount: macroValue
      });
    } else {
      macro.amount += macroValue;
    }

    return macros;
  }, []);

  const { fibre, sugar } = food.food_macros.reduce<{
    fibre: number;
    sugar: number;
  }>(
    (total, macro) => {
      if (!macro.macro) {
        return total;
      }

      switch (macro.macro.name) {
        case "Fibre":
          total.fibre += macro.value;
          break;

        case "Sugar":
          total.sugar += macro.value;
          break;
      }

      return total;
    },
    { fibre: 0, sugar: 0 }
  );

  const vitaminMinerals: {
    name: string;
    value: number;
    unit: string;
  }[] = food.food_minerals
    .map(({ mineral, unit, value }) => ({
      name: mineral.name,
      unit: unit.abbrev,
      value
    }))
    .concat(
      food.food_vitamins.map(({ vitamin, unit, value }) => ({
        name: vitamin.name,
        unit: unit.abbrev,
        value
      }))
    )
    .sort(({ value: aValue }, { value: bValue }) => (aValue < bValue ? 1 : -1));

  return (
    <React.Fragment>
      {/* <Helmet>
        <meta property="og:site_name" content={`${food.name} - Marrow`} />
        <meta
          property="og:url"
          content={`https://lifeofmarrow.com/recipe?name=${food.name}`}
        />
        <meta property="og:image" content={imageUrl(food.image_url || "")} />
      </Helmet> */}

      <Grid container justify={isMobile ? "center" : undefined}>
        <Grid item xs={12}>
          <CardHeader
            title={
              <Typography
                variant="h1"
                color="primary"
                align={isMobile ? "center" : "left"}
              >
                {food.name}{" "}
                {food.featured && (
                  <Tooltip title="Featured">
                    <StarRateIcon />
                  </Tooltip>
                )}
              </Typography>
            }
            subheader={
              <Typography
                style={{ paddingTop: HEADER_PADDING / 5 }}
                variant="h2"
                color="textSecondary"
                align={isMobile ? "center" : "left"}
              >
                {food.genus && food.genus.name}
                &nbsp;
                {food.specie && food.specie.name.toLocaleLowerCase()}
              </Typography>
            }
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <CardMedia
            className={classes.foodImage}
            image={imageUrl(food.image_url)}
            title={food.name}
          />
        </Grid>

        {sugar || fibre ? (
          <Grid item md={4} xs={12}>
            <CardHeader
              title={
                <Typography variant="h1" color="primary" align="center">
                  100g
                </Typography>
              }
            />
            <Grid container>
              <Grid item xs={6}>
                <Grid container>
                  {macroTypes.map((fm, i) => (
                    <React.Fragment key={i}>
                      <Grid item xs={6}>
                        <Typography
                          className={classes.foodStatName}
                          variant="body2"
                        >
                          {fm.macro}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          className={classes.foodStatValue}
                        >
                          {fm.amount.toFixed(2)}g
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={6} className={classes.rightStats}>
                <Grid container>
                  {food.kcal ? (
                    <React.Fragment>
                      <Grid item xs={6}>
                        <Typography
                          className={classes.foodStatName}
                          variant="body2"
                        >
                          Calories
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          className={classes.foodStatValue}
                        >
                          {food.kcal}kcal
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <br />
                  )}

                  {
                    <React.Fragment>
                      <Grid item xs={6}>
                        <Typography
                          className={classes.foodStatName}
                          variant="body2"
                        >
                          Fibre
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          className={classes.foodStatValue}
                        >
                          {fibre}g
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  }

                  {
                    <React.Fragment>
                      <Grid item xs={6}>
                        <Typography
                          className={classes.foodStatName}
                          variant="body2"
                        >
                          Sugar
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          className={classes.foodStatValue}
                        >
                          {sugar}g
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  }
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <br />
              </Grid>

              <Grid item xs={6}>
                <Grid container>
                  {vitaminMinerals.splice(0, 3).map((vm, i) => (
                    <React.Fragment key={i}>
                      <Grid item xs={6}>
                        <Typography
                          className={classes.foodStatName}
                          variant="body2"
                        >
                          {vm.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          className={classes.foodStatValue}
                        >
                          {vm.value.toFixed(2)}
                          {vm.unit}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={6} className={classes.rightStats}>
                <Grid container>
                  {vitaminMinerals.splice(0, 3).map((vm, i) => (
                    <React.Fragment key={i}>
                      <Grid item xs={6}>
                        <Typography
                          className={classes.foodStatName}
                          variant="body2"
                        >
                          {vm.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          className={classes.foodStatValue}
                        >
                          {vm.value.toFixed(2)}
                          {vm.unit}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ paddingTop: HEADER_PADDING / 2 }}>
                {food.tips.map(t => (
                  <Chip
                    key={t.id}
                    className={classes.tip}
                    variant="outlined"
                    color="primary"
                    avatar={
                      <Avatar classes={chipStyles}>
                        <Lightbulb color="secondary" />
                      </Avatar>
                    }
                    label={
                      <Typography style={{ fontSize: "0.7rem" }}>
                        {t.tip}
                      </Typography>
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        <Grid item md={4} xs={12}>
          <CardHeader
            title={
              <Typography variant="h1" color="primary" align="center">
                Facts
              </Typography>
            }
          />

          <Grid container justify={isMobile ? "center" : undefined}>
            <Grid item sm />

            <Grid item>
              {food.maltese_name && (
                <Typography
                  variant="body1"
                  className={classes.fact}
                  style={{ fontStyle: "italic" }}
                  align={isMobile ? "center" : "left"}
                >
                  <span
                    role="img"
                    aria-label="maltese_flag"
                    className={classes.icon}
                  >
                    🇲🇹
                  </span>{" "}
                  {food.maltese_name}
                </Typography>
              )}

              {food.type && (
                <Typography
                  variant="body1"
                  className={classes.fact}
                  align={isMobile ? "center" : "left"}
                >
                  <RestaurantMenuIcon className={classes.icon} />

                  {food.type.name}
                </Typography>
              )}

              <Typography
                variant="body1"
                className={classes.fact}
                align={isMobile ? "center" : "left"}
              >
                <LanguageIcon className={classes.icon} />

                {food.origin_region ? food.origin_region.name : "Global"}
              </Typography>

              <Typography
                variant="body1"
                className={classes.fact}
                align={isMobile ? "center" : "left"}
              >
                <WbSunnyIcon className={classes.icon} />

                {food.climate ? food.climate.name : "Global"}
              </Typography>

              <Typography
                variant="body1"
                className={classes.fact}
                align={isMobile ? "center" : "left"}
              >
                <DateRangeIcon className={classes.icon} />

                {food.season_start && food.season_end
                  ? `${food.season_start.name} - ${food.season_end.name}`
                  : "All Year"}
              </Typography>
            </Grid>

            <Grid item sm />
          </Grid>
        </Grid>

        <Query<RecipesQuery, RecipesQueryVariables>
          query={recipesGQL}
          variables={{ count: 16, page: 1, foodIds: [food.id] }}
        >
          {({ data }) => (
            <React.Fragment>
              {!data || !data.recipes ? (
                <Loader />
              ) : (
                data.recipes.recipes.length > 0 && (
                  <Grid
                    item
                    container
                    spacing={3}
                    md={12}
                    style={{ paddingTop: HEADER_PADDING }}
                  >
                    <Grid item xs={12}>
                      <CardHeader
                        title={
                          <Typography variant="h2" color="primary">
                            Recipes
                          </Typography>
                        }
                      />
                    </Grid>

                    {data.recipes.recipes.map(r => (
                      <Grid key={r.id} item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <RecipeTile recipe={r} />
                      </Grid>
                    ))}
                  </Grid>
                )
              )}
            </React.Fragment>
          )}
        </Query>

        {food.blogPosts && food.blogPosts.length > 0 && (
          <Grid
            item
            container
            spacing={3}
            md={12}
            style={{ paddingTop: HEADER_PADDING }}
          >
            <Grid item xs={12}>
              <CardHeader
                title={
                  <Typography variant="h2" color="primary">
                    Posts
                  </Typography>
                }
              />
            </Grid>

            {food.blogPosts.map(bp => (
              <Grid key={bp.id} item xl={4} lg={4} md={6} sm={6} xs={12}>
                <BlogTile blogPost={bp} />
              </Grid>
            ))}
          </Grid>
        )}

        <BackButton />
      </Grid>
    </React.Fragment>
  );
};
