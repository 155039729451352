import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { isMobile } from "../../../../AppLayout";

export const useStyles = makeStyles<Theme>(theme => ({
  stat: {
    marginBottom: theme.spacing(1),
    width: "100%",
    justifyContent: "flex-start"
  },

  bar: {
    display: "flex",
    justifyContent: "space-between"
  },

  pinch: {
    display: "flex",
    justifyContent: "center"
  },

  foodImage: {
    height: 250,
    width: !isMobile ? 250 : "unset",
    backgroundSize: "contain"
  },

  foodStatValue: {
    fontSize: 11,
    textAlign: "right",
    paddingRight: theme.spacing(2)
  },

  foodStatName: {
    fontSize: 13
  },

  rightStats: {
    borderLeft: "2px solid",
    borderColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(2)
  },

  tip: {
    marginBottom: theme.spacing(1),
    width: "100%",
    justifyContent: "flex-start"
  },

  icon: {
    color: theme.palette.primary.main,
    paddingRight: theme.spacing(1),
    fontSize: 26,
    marginBottom: -theme.spacing(2),
    height: theme.spacing(4)
  },

  fact: {
    fontSize: 16,
    marginBottom: theme.spacing(2)
  }
}));
