import { Card, CardActionArea, CardHeader, CardMedia, Tooltip, Typography } from "@material-ui/core";
import StarRateIcon from "@material-ui/icons/StarRate";
import * as React from "react";
import { Link } from "react-router-dom";
import { Loader } from "..";
import { imageUrl, toUrl } from "../../lib";
import { HomepageFeaturedFood_foods_foods } from "../../views/Homepage/components/Featured/types/HomepageFeaturedFood";
import { useStyles } from "./style";

interface Props {
  food: HomepageFeaturedFood_foods_foods;
}

export const FoodTile: React.FC<Props> = ({ food }) => {
  const [loaded, setLoaded] = React.useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <Link
        to={{ pathname: `/food/${toUrl(food.name)}` }}
        style={{ textDecoration: "none" }}
      >
        <Card>
          <CardActionArea>
            <CardHeader
              title={
                <div className={classes.bar}>
                  <Typography variant="h3" color="primary">
                    {food.name}
                  </Typography>

                  {food.featured && (
                    <Tooltip title="Featured">
                      <StarRateIcon color="primary" />
                    </Tooltip>
                  )}
                </div>
              }
              subheader={
                <Typography variant="h4" color="textSecondary">
                  {food.genus && food.genus.name}
                  &nbsp;
                  {food.specie && food.specie.name.toLocaleLowerCase()}
                </Typography>
              }
            />

            <CardMedia
              style={{
                height: 250,
                width: "100%",
                margin: "auto",
                backgroundSize: "contain"
              }}
              image={loaded ? imageUrl(food.image_url) : "."}
              title={food.name}
            >
              {!loaded && (
                <Loader
                  imageSrc={imageUrl(food.image_url)}
                  onImgLoad={e => {
                    setLoaded(true);
                  }}
                />
              )}
            </CardMedia>
          </CardActionArea>
        </Card>
      </Link>
    </React.Fragment>
  );
};
