import { Card, CardMedia, Grid, Typography, Chip } from "@material-ui/core";
import { format } from "date-fns";
import * as React from "react";
import renderHTML from "react-render-html";
import { HEADER_PADDING, isMobile } from "../../../AppLayout";
import { Loader } from "../../../components";
import { imageUrl, parseGQLDate } from "../../../lib";
import { BlogPostQuery_blogpost } from "../types/BlogPostQuery";
import { Link } from "react-router-dom";

interface Props {
  blogPost: BlogPostQuery_blogpost;
}

export const BlogPost: React.FC<Props> = ({
  blogPost: { image_url, published_on, tags, text, title }
}) => {
  const [loaded, setLoaded] = React.useState(false);
  const imageRef = React.useRef<HTMLBaseElement>(null);
  const [imageWidth, setImageWidth] = React.useState(0);

  React.useEffect(() => {
    setImageWidth(imageRef.current ? imageRef.current.clientWidth : 0);
  }, [loaded]);

  return (
    <Grid
      container
      spacing={3}
      justify={isMobile ? "center" : "space-around"}
      style={{ paddingTop: HEADER_PADDING / 2 }}
    >
      <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
        {!loaded ? (
          <Loader
            imageSrc={imageUrl(image_url || "")}
            onImgLoad={e => setLoaded(true)}
          />
        ) : (
          <Card
            ref={imageRef}
            style={{
              height: imageWidth,
              margin: isMobile ? "auto" : "unset"
            }}
          >
            <CardMedia
              image={imageUrl(image_url || "")}
              style={{
                height: imageWidth,
                backgroundSize: "contain",
                backgroundPosition: isMobile ? "center" : "left top"
              }}
            />
          </Card>
        )}
        {tags && (
          <Grid
            container
            spacing={1}
            style={{ paddingTop: HEADER_PADDING / 2 }}
          >
            <Grid item xs={12}>
              <Typography variant="h3" color="textPrimary">
                Tags
              </Typography>
            </Grid>

            <React.Fragment>
              {tags
                .split(",")
                .map(t => t.trim())
                .map((t, i) => (
                  <Grid item>
                    <Link
                      key={i}
                      to={{ pathname: "/blog", search: `?search=${t}` }}
                      style={{ textDecoration: "none" }}
                    >
                      <Chip color="primary" label={t} />
                    </Link>
                  </Grid>
                ))}
            </React.Fragment>
          </Grid>
        )}
      </Grid>

      <Grid item xl={8} lg={8} md={7} sm={12} xs={12}>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Typography variant="h2" align={isMobile ? "center" : "left"}>
              {title}
            </Typography>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            style={{ textAlign: isMobile ? "center" : "right" }}
          >
            <Typography variant="caption" align={isMobile ? "center" : "left"}>
              Published on {format(parseGQLDate(published_on), "dd MMM yyyy")}
            </Typography>
          </Grid>
        </Grid>

        <Typography component="div">{renderHTML(text)}</Typography>
      </Grid>
    </Grid>
  );
};
