import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles<Theme>({
  homepageBackground: {
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100vh"
  }
});
