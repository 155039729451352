import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { loader } from "graphql.macro";
import * as React from "react";
import { useMutation } from "react-apollo";
import { usePrevious } from "../../../hooks";
import {
  CashOnDeliveryMutation,
  CashOnDeliveryMutationVariables,
} from "./types/CashOnDeliveryMutation";

interface Props extends DialogProps {}

const CashOnDeliveryGQL = loader("./Mutation.graphql");

export const CashOnDeliveryDialog: React.FC<Props> = ({ ...dialogProps }) => {
  const [fullName, setFullName] = React.useState<string>();
  const [emailAddress, setEmailAddress] = React.useState<string>();
  const [address, setAddress] = React.useState<string>();

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = !prevOpen && dialogProps.open;

  React.useEffect(() => {
    if (justOpened) {
      setFullName(undefined);
      setEmailAddress(undefined);
      setAddress(undefined);
    }
  }, [justOpened]);

  const [mutate, { loading }] = useMutation<
    CashOnDeliveryMutation,
    CashOnDeliveryMutationVariables
  >(CashOnDeliveryGQL);

  const canSubmit = Boolean(fullName && emailAddress && address);

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Cash on Delivery Order</DialogTitle>
      {loading && <LinearProgress />}

      <DialogContent>
        <Typography variant="body1">
          You are currently making a cash-on-delivery order where the amount of
          &euro;34.99 will be paid upon delivery of the book via Revolut, BOV
          Pay or Cash.
        </Typography>

        <TextField
          required
          placeholder="Full Name"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          type="email"
          required
          placeholder="Email Address"
          value={emailAddress}
          onChange={(e) => setEmailAddress(e.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          type="text"
          required
          placeholder="Delivery Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() =>
            dialogProps.onClose
              ? dialogProps.onClose({}, "backdropClick")
              : undefined
          }
        >
          Close
        </Button>
        <Button onClick={onSubmit} color="primary" disabled={!canSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );

  async function onSubmit() {
    await mutate({
      variables: {
        address: address ?? "",
        emailAddress: emailAddress ?? "",
        fullName: fullName ?? "",
      },
    });

    if (dialogProps.onClose) {
      dialogProps.onClose({}, "backdropClick");
    }
  }
};
