import * as React from "react";
import { HEADER_PADDING } from "../../AppLayout";
import { Button, Theme, useTheme } from "@material-ui/core";

interface Props {
  totalItems: number;
  currentPage: number;

  setCurrentPage(page: number): void;
}

export const PAGE_SIZE = 12;

export const Pager: React.FC<Props> = ({
  totalItems,
  currentPage,
  setCurrentPage
}) => {
  const theme = useTheme<Theme>();

  const pages = Array.from(new Array(Math.ceil(totalItems / PAGE_SIZE)));

  // const extraPages = pages.length > 3;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: HEADER_PADDING
      }}
    >
      {currentPage > 1 && (
        <Button color="primary" onClick={e => setCurrentPage(currentPage - 1)}>
          {"<"}
        </Button>
      )}

      {pages.map((_, i) => (
        <Button
          key={i}
          onClick={e => setCurrentPage(i + 1)}
          style={{
            color:
              currentPage === i + 1
                ? theme.palette.primary.main
                : theme.palette.grey[800]
          }}
        >
          {i + 1}
        </Button>
      ))}

      {totalItems / PAGE_SIZE > currentPage && (
        <Button color="primary" onClick={e => setCurrentPage(currentPage + 1)}>
          {">"}
        </Button>
      )}
    </div>
  );
};
