import DateFnsUtils from "@date-io/date-fns";
import { Container, createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers/MuiPickersUtilsProvider";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import React from "react";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter, Route } from "react-router-dom";
import "./App.css";
import { AppLayout, HEADER_PADDING, MarrowRoutes } from "./AppLayout";
import { Footer, Navigation } from "./components";
import { apiUrl } from "./lib";

interface Props {}

const App: React.FC<Props> = () => {
  const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: apiUrl,
      credentials: "same-origin",
    }),
  ]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  const mainColor = "#009966";
  const white = "#fff";
  const grey = "#333";

  const theme = createTheme({
    palette: {
      primary: { main: mainColor },
      secondary: { main: white },
      text: { primary: grey },
    },
    typography: {
      h1: { fontFamily: "OpenSans-ExtraBold", color: grey, fontSize: 28 },
      h2: { fontFamily: "OpenSans-ExtraBold", color: grey, fontSize: 24 },
      h3: {
        fontFamily: "OpenSans-ExtraBold",
        color: white,
        fontSize: 18,
        lineHeight: 1.5,
      },
      h4: { fontFamily: "OpenSans-Bold", color: white, fontSize: 14 },
      subtitle2: { fontFamily: "Antipasto" },
      body1: { fontFamily: "OpenSans" },
      body2: { fontFamily: "OpenSans-Bold" },
    },
  });

  return (
    <ApolloProvider client={client}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AppLayout>
              {(isHomepage, routeProps) => (
                <React.Fragment>
                  {!isHomepage && <Navigation {...routeProps} />}

                  {MarrowRoutes.map(({ hidden, label, ...r }, i) => {
                    const route = <Route exact={false} {...r} />;

                    return isHomepage ? (
                      <React.Fragment key={i}>{route}</React.Fragment>
                    ) : (
                      <Container
                        maxWidth="xl"
                        style={{ paddingTop: HEADER_PADDING / 4 }}
                        key={i}
                      >
                        {route}
                      </Container>
                    );
                  })}

                  {!isHomepage && <Footer />}

                  {/* <Helmet>
                    <meta charSet="utf-8" />
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1.0"
                    />
                    <meta
                      name="description"
                      content="Welcome to the Marrow website. Feel free to browse our library of recipes, food, information & more!"
                    />
                    <meta name="msapplication-config" content="none" />
                    <link
                      rel="shortcut icon"
                      type="image/png"
                      href="/favicon.png"
                    />
                    <meta name="robots" content="NOODP" />
                    <meta name="robots" content="index" />
                    <meta
                      name="keywords"
                      content="marrow, marrow malta, malta, health, blog, vegan, environment, plant based, meat free, health malta, healthy recipes, vegan recipes, meat free recipes, plant based recipes, blog, food information, food nutrition, nutrition"
                    />

                    <meta
                      property="og:title"
                      content="Health, Environment, Wellbeing | Marrow®"
                    />
                    <meta property="og:type" content="article" />
                    <meta
                      property="og:description"
                      content="Welcome to the Marrow website. Feel free to browse our library of recipes, food, information & more!"
                    />

                    <meta property="fb:pages" content="266719697028950" />
                  </Helmet> */}
                </React.Fragment>
              )}
            </AppLayout>
          </BrowserRouter>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </ApolloProvider>
  );
};

export default App;

