import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles<Theme>(theme => ({
  filteringChip: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    zIndex: 2
  }
}));
