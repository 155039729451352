import { Theme, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Navigation } from "../../../../components";
import { useStyles } from "./style";

interface Props extends RouteComponentProps {
  backgroundUrl: string;

  setScrolled(scrolled: boolean): void;
}

export const Splash: React.FC<Props> = ({
  backgroundUrl,
  setScrolled,
  ...routeProps
}) => {
  const theme = useTheme<Theme>();
  const classes = useStyles(theme);

  return (
    <div
      className={classes.homepageBackground}
      style={{
        backgroundImage: `url('${backgroundUrl}')`
      }}
      onClick={e => setScrolled(true)}
    >
      <Navigation isHomepage={true} {...routeProps} />
      <div
        style={{
          position: "absolute",
          bottom: 10,
          width: "100%",
          textAlign: "center",
          fontSize: 30,
          color: "white",
          cursor: "pointer"
        }}
      >
        <Typography variant="h3">Click to Marrow</Typography>
      </div>
    </div>
  );
};
