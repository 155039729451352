import {
  CircularProgress,
  Grid,
  InputAdornment,
  TextField
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { loader } from "graphql.macro";
import * as React from "react";
import { Query } from "react-apollo";
import { useHistory } from "react-router-dom";
import { HEADER_PADDING, isMobile } from "../../../AppLayout";
import { Dropdown, DropdownOption } from "../../../components";
import { BlogTile } from "./BlogTile";
import { BlogQuery, BlogQueryVariables } from "./types/BlogQuery";
import { CriteriaQuery } from "./types/CriteriaQuery";

interface Props {
  search: string | null;
  filterDate: Date | null;
  categoryName: string | null;
}

const criteriaQuery = loader("./CriteriaQuery.graphql");
const query = loader("./Query.graphql");

export const BlogSearch: React.FC<Props> = ({
  categoryName,
  filterDate,
  search
}) => {
  const history = useHistory();
  const [blogSearchText, setBlogSearchText] = React.useState(search);
  // const [blogDate, setBlogDate] = React.useState<Date>(
  //   filterDate || setMonth(new Date(), 0)
  // );
  const [blogCategory, setBlogCategory] = React.useState(categoryName);
  const [categoryDropdownOpen, setCategoryDropdownOpen] = React.useState(false);

  return (
    <Query<CriteriaQuery> query={criteriaQuery}>
      {({ loading, data }) => {
        if (loading || !data || !data.blogcategories) {
          return <CircularProgress variant="indeterminate" />;
        }

        const selectedCategory = data.blogcategories.find(
          bc => bc.name === blogCategory
        );

        return (
          <Grid
            container
            style={{
              paddingTop: HEADER_PADDING
            }}
            spacing={3}
            justifyContent="flex-start"
          >
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Dropdown
                dropdownOpen={categoryDropdownOpen}
                setDropdownOpen={open => setCategoryDropdownOpen(open)}
                label="Filter by Category"
                selectedName={blogCategory || ""}
                setSelectedName={name => {
                  setBlogCategory(name || "");

                  history.push({
                    search: "?category=" + name ? name : ""
                  });
                }}
                options={data.blogcategories.map<DropdownOption>(bc => ({
                  label: bc.name,
                  value: bc.id
                }))}
              />
            </Grid>

            <Grid item lg={4} md={6} sm={12} xs={12}>
              {/* <DatePicker
                value={blogDate}
                autoOk
                format={"MMMM yyyy"}
                label="Filter by Date"
                views={["month"]}
                onChange={(date: MaterialUiPickersDate) => {
                  if (date) {
                    setBlogDate(date);
                  }
                }}
              /> */}
            </Grid>

            <Grid item lg={4} md={6} sm={12} xs={12}>
              <TextField
                placeholder="Search by title"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                value={blogSearchText || ""}
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                  setBlogSearchText(target.value)
                }
                onKeyPress={(e: React.KeyboardEvent) => {
                  if (e.charCode === 13) {
                    history.push({
                      search: "?search=" + blogSearchText
                    });
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid
              item
              container
              xs={12}
              spacing={isMobile ? 2 : 5}
              justifyContent="space-evenly"
            >
              <Query<BlogQuery, BlogQueryVariables>
                query={query}
                variables={{
                  categoryId: selectedCategory ? selectedCategory.id : null,
                  search
                }}
              >
                {({ loading: postsLoading, data: postsData }) => (
                  <React.Fragment>
                    {postsLoading ||
                      !postsData ||
                      (!postsData.blogposts && (
                        <CircularProgress variant="indeterminate" />
                      ))}

                    {postsData &&
                      postsData.blogposts &&
                      postsData.blogposts.blogPosts.map(bp => (
                        <BlogTile blogPost={bp} key={bp.id} />
                      ))}
                  </React.Fragment>
                )}
              </Query>
            </Grid>
          </Grid>
        );
      }}
    </Query>
  );
};
