import { Container, Grid, Slide, Typography } from "@material-ui/core";
import { loader } from "graphql.macro";
import * as React from "react";
import { useQuery } from "react-apollo";
import { RouteComponentProps } from "react-router";
import { HEADER_PADDING } from "../../../../AppLayout";
import { FoodTile, Footer, Loader, Navigation } from "../../../../components";
import { BlogTile } from "../../../Blog/components";
import { RecipeTile } from "../../../Recipe/components/RecipeSearch/RecipeTile";
import {
  HomepageFeaturedFood,
  HomepageFeaturedFoodVariables
} from "./types/HomepageFeaturedFood";
import { LatestBlogPostsQuery } from "./types/LatestBlogPostsQuery";
import {
  LatestRecipesQuery,
  LatestRecipesQueryVariables
} from "./types/LatestRecipesQuery";

const featuredFoodQuery = loader("./FeaturedFoodQuery.graphql");
const latestBlogPostsQuery = loader("./LatestBlogPostsQuery.graphql");
const latestRecipesQuery = loader("./LatestRecipesQuery.graphql");

interface Props extends RouteComponentProps {
  scrolled: boolean;
}

export const Featured: React.FC<Props> = ({ scrolled, ...routeProps }) => {
  const { data: foodData, loading: foodLoading } = useQuery<
    HomepageFeaturedFood,
    HomepageFeaturedFoodVariables
  >(featuredFoodQuery, {
    variables: {
      count: 12,
      page: 1,
      featured: true
    }
  });
  const { data: recipeData, loading: recipesLoading } = useQuery<
    LatestRecipesQuery,
    LatestRecipesQueryVariables
  >(latestRecipesQuery, { variables: { count: 6, page: 1 } });

  const { data: blogData, loading: blogLoading } = useQuery<
    LatestBlogPostsQuery
  >(latestBlogPostsQuery);

  return (
    <Slide in={scrolled} direction="up" timeout={1000}>
      <div style={{ overflowY: "scroll", height: "100vh" }}>
        <Navigation isHomepage={false} {...routeProps} />

        <Container maxWidth="lg" style={{ paddingTop: HEADER_PADDING }}>
          <Typography variant="h2" color="primary">
            Latest Recipes
          </Typography>
          {(recipesLoading || !recipeData || !recipeData.recipes) && <Loader />}
          {recipeData && recipeData.recipes && (
            <Grid
              container
              spacing={3}
              justifyContent="space-evenly"
              style={{ paddingTop: HEADER_PADDING / 2 }}
            >
              {recipeData.recipes.recipes.map((r, i) => (
                <Grid item xl={4} lg={4} sm={6} xs={12} key={i}>
                  <RecipeTile recipe={r} />
                </Grid>
              ))}
            </Grid>
          )}
        </Container>

        <Container maxWidth="lg" style={{ paddingTop: HEADER_PADDING }}>
          <Typography variant="h2" color="primary">
            Seasonal Maltese Produce
          </Typography>

          {(foodLoading || !foodData || !foodData.foods) && <Loader />}

          {foodData && foodData.foods && (
            <Grid
              container
              spacing={3}
              justifyContent="space-evenly"
              style={{ paddingTop: HEADER_PADDING / 2 }}
            >
              {foodData.foods.foods.map((f, i) => (
                <Grid item xl={3} lg={3} sm={6} xs={12} key={i}>
                  <FoodTile food={f} />
                </Grid>
              ))}
            </Grid>
          )}
        </Container>

        <Container maxWidth="lg" style={{ paddingTop: HEADER_PADDING }}>
          <Typography variant="h2" color="primary">
            Latest Posts
          </Typography>

          {(blogLoading || !blogData || !blogData.blogposts) && <Loader />}

          {blogData && blogData.blogposts && (
            <Grid
              container
              spacing={3}
              justifyContent="space-evenly"
              style={{ paddingTop: HEADER_PADDING / 2 }}
            >
              {blogData.blogposts.blogPosts.map((bp, i) => (
                <Grid item xl={4} lg={4} sm={12} xs={12} key={i}>
                  <BlogTile blogPost={bp} />
                </Grid>
              ))}
            </Grid>
          )}
        </Container>

        <Footer />
      </div>
    </Slide>
  );
};
