import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles<Theme>(({ palette, spacing }) => ({
  topbar: {
    padding: `0 ${spacing(5)}px`,
    display: "flex",
    justifyContent: "space-between",

    "&:not(.home)": {
      boxShadow: "0px -12px 21px 0px"
    }
  },

  navigation: {
    listStyle: "none",
    display: "inline-flex",
    textAlign: "initial",
    padding: 0,
    margin: `${spacing(7)}px 0 0 0`
  },

  mobileMenu: {
    position: "absolute",
    right: 0,
    marginRight: "3vw",
    marginTop: "3vw",
    zIndex: 2
  },

  mobileMenuExpanded: {
    marginTop: "calc(3vw + 56px)",
    backgroundColor: palette.primary.main,
    color: palette.secondary.main,
    cursor: "pointer",
    width: 150
  },

  mobileMenuItem: {
    "&:hover": {
      backgroundColor: palette.secondary.main,
      color: palette.primary.main
    }
  },
  //   dropdown: {
  //     display: "none",
  //     position: "absolute",
  //     top: 50,
  //     backgroundColor: palette.primary.main,
  //     border: "1px solid",
  //     borderColor: palette.primary.main,
  //     zIndex: 99
  //   },

  menuItem: {
    display: "inline-block",
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
    lineHeight: `${spacing(6)}px`,
    fontSize: 22,
    cursor: "pointer",
    textAlign: "center",
    textDecoration: "none",
    color: palette.primary.main,
    fontFamily: "Antipasto",

    "&.notHome": {
      color: palette.grey[800]
    },

    "&.home": {
      color: palette.secondary.main,

      "&:visited": {
        color: palette.secondary.main
      },

      "&:hover": {
        color: palette.primary.main
      }
    },

    "&:visited": {
      color: palette.grey[900]
    },

    "&:hover": {
      color: palette.primary.main
    }
  }
}));
