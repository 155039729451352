import {
  Button,
  Container,
  Grid,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { loader } from "graphql.macro";
import { Facebook, Instagram } from "mdi-material-ui";
import * as React from "react";
import { useMutation } from "react-apollo";
import { Link } from "react-router-dom";
import { HEADER_PADDING, isMobile } from "../../AppLayout";
import {
  SubscribeMutation,
  SubscribeMutationVariables,
} from "./types/SubscribeMutation";

const mutation = loader("./SubscribeMutation.graphql");

export const Footer: React.FC = () => {
  const theme = useTheme<Theme>();
  const [email, setEmail] = React.useState<string>();
  const [subscribeResult, setSubscribeResult] = React.useState<string>();

  const [subscribeNewsletter, { loading }] = useMutation<
    SubscribeMutation,
    SubscribeMutationVariables
  >(mutation);

  return (
    <div
      style={{
        backgroundColor: theme.palette.primary.main,
        marginTop: HEADER_PADDING,
        padding: `${theme.spacing(5)}px 0`,
      }}
    >
      <Container>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={4}
            style={{ textAlign: isMobile ? "center" : "left" }}
          >
            {subscribeResult ? (
              <Typography color="secondary">{subscribeResult}</Typography>
            ) : (
              <React.Fragment>
                <TextField
                  placeholder="Email Address"
                  name="txtEmail"
                  type="email"
                  label=""
                  value={email || ""}
                  onChange={(e) => setEmail(e.target.value)}
                  inputProps={{
                    style: {
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.secondary.main,
                      textAlign: isMobile ? "center" : "left",
                      padding: `${theme.spacing(1)}px`,
                    },
                  }}
                />
                &nbsp;&nbsp;
                <Button
                  color="secondary"
                  variant="outlined"
                  style={{ textTransform: "capitalize" }}
                  onClick={onClickSubscribe}
                  disabled={loading}
                >
                  {!loading ? "Subscribe" : "Loading"}
                </Button>
              </React.Fragment>
            )}
          </Grid>

          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            md={5}
            spacing={2}
            style={{
              textAlign: "center",
              paddingTop: isMobile ? HEADER_PADDING : 0,
            }}
          >
            <Grid item xs={12} md={3}>
              <Typography color="secondary" align="center">
                <Link
                  to={{ pathname: "/" }}
                  style={{
                    textDecoration: "none",
                    color: theme.palette.secondary.main,
                  }}
                >
                  Home
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography color="secondary" align="center">
                <Link
                  to={{ pathname: "/recipes" }}
                  style={{
                    textDecoration: "none",
                    color: theme.palette.secondary.main,
                  }}
                >
                  Recipes
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography color="secondary" align="center">
                <Link
                  to={{ pathname: "/blog" }}
                  style={{
                    textDecoration: "none",
                    color: theme.palette.secondary.main,
                  }}
                >
                  Blog
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography color="secondary" align="center">
                <Link
                  to={{ pathname: "/disclaimer" }}
                  style={{
                    textDecoration: "none",
                    color: theme.palette.secondary.main,
                  }}
                >
                  Privacy Policy &amp; Disclaimer
                </Link>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            style={{
              paddingTop: isMobile ? HEADER_PADDING : 0,
            }}
          >
            <Typography color="secondary" align={isMobile ? "center" : "right"}>
              Follow Us &nbsp;&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/marrowhealth/"
              >
                <Facebook color="secondary" />
              </a>
              &nbsp;&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/marrowhealth/"
              >
                <Instagram color="secondary" />
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );

  async function onClickSubscribe() {
    try {
      if (!email) {
        throw new Error("Email address is required");
      }

      const { data, errors } = await subscribeNewsletter({
        variables: { email },
      });

      if (errors) {
        throw new Error(errors.map((e) => e.message).join("\n"));
      }

      if (!data || !data.subscribeNewsletter) {
        throw new Error("Error");
      }

      setSubscribeResult("Subscribed! Thank you!");
    } catch (error) {
      setSubscribeResult(error.message);
    }
  }
};
