import { Container, Typography } from "@material-ui/core";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { HEADER_PADDING, isMobile } from "../../AppLayout";

interface Props extends RouteComponentProps {}

export const Legal: React.FC<Props> = () => {
  return (
    <React.Fragment>
      <Typography
        variant="h1"
        color="primary"
        align={isMobile ? "center" : "left"}
      >
        Privacy Policy &amp; Disclaimer
      </Typography>

      <Container>
        <Typography component="div" style={{ paddingTop: HEADER_PADDING }}>
          <p>
            <strong>PRIVACY POLICY</strong>
          </p>

          <p>Effective as of August 17, 2018</p>

          <p>
            As the owner of this website, lifeofmarrow.com we understand that
            your privacy is of critical importance. &nbsp;This Privacy Policy
            describes what information we collect from you through the Site and
            how we use and disclose such information.
          </p>

          <p>
            <strong>Our Use of Cookies</strong>
          </p>

          <p>
            A cookie is a file containing an identifier (a string of letters and
            numbers) that is sent by a web server to a web browser and is stored
            by the browser. The identifier is then sent back to the server each
            time the browser requests a page from the server. Cookies may be
            either &ldquo;persistent&rdquo; cookies or &ldquo;session&rdquo;
            cookies: a persistent cookie will be stored by a web browser and
            will remain valid until its set expiry date, unless deleted by the
            user before the expiry date; a session cookie, on the other hand,
            will expire at the end of the user session, when the web browser is
            closed. &nbsp;Cookies do not typically contain any information that
            personally identifies a user, but personal information that we store
            about you may be linked to the information stored in and obtained
            from cookies.
          </p>

          <p>We use cookies for the following purposes:</p>

          <p>
            &nbsp;(a) authentication &ndash; we use cookies to identify you when
            you visit our website and as you navigate our website];
          </p>

          <p>
            &nbsp;(b) personalization &ndash; we use cookies to store
            information about your preferences and to personalize the website
            for you;
          </p>

          <p>
            &nbsp;(c) security &ndash; we use cookies as an element of the
            security measures used to protect our website and services
            generally;
          </p>

          <p>
            &nbsp;(d) analysis &ndash; we use cookies to help us to analyze the
            use and performance of our website and services;
          </p>

          <p>
            We use Google Analytics to analyze the use of our website. Google
            Analytics gathers information about website use by means of cookies.
            The information gathered relating to our website is used to create
            reports about the use of our website. Google&rsquo;s privacy policy
            is available at:&nbsp;
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.google.com/policies/privacy/
            </a>
          </p>

          <p>
            Most browsers allow you to refuse to accept cookies and to delete
            cookies. The methods for doing so vary from browser to browser, and
            from version to version. You can however obtain up-to-date
            information about blocking and deleting cookies via these links:
          </p>

          <p>
            (a)&nbsp;
            <a
              href="https://support.google.com/chrome/answer/95647?hl=en"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://support.google.com/chrome/answer/95647?hl=en
            </a>
            &nbsp;(Chrome);
          </p>

          <p>
            (b)&nbsp;
            <a
              href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
            </a>
            (Firefox);
          </p>

          <p>
            (c)&nbsp;
            <a
              href="https://www.opera.com/help/tutorials/security/cookies/"
              rel="noopener noreferrer"
              target="_blank"
            >
              http://www.opera.com/help/tutorials/security/cookies/
            </a>
            &nbsp;(Opera);
          </p>

          <p>
            (d)&nbsp;
            <a
              href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
            &nbsp;(Internet Explorer);
          </p>

          <p>
            (e)&nbsp;
            <a
              href="https://support.apple.com/kb/PH21411"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://support.apple.com/kb/PH21411
            </a>
            &nbsp;(Safari); and
          </p>

          <p>
            (f)&nbsp;
            <a
              href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
            </a>
            &nbsp;(Edge).
          </p>

          <p>
            Please note that blocking cookies may have a negative impact on the
            functions of many websites, including our Site. Some features of the
            Site may cease to be available to you.
          </p>

          <p>&nbsp;</p>

          <p>For European Economic Area Residents</p>

          <p>
            If you reside in a country in the European Economic Area (EEA), then
            under the GDPR and applicable data protection laws you have the
            rights, among other things, to access your personal data, have us
            erase it, and/or restrict its further processing. &nbsp;If you wish
            to access or delete your personal data (if any) maintained by us or,
            you can contact us at&nbsp;daniel@marrowhealh.com.
          </p>

          <p>&nbsp;</p>

          <p>
            <strong>Email Addresses</strong>
          </p>

          <p>
            We may collect your email address, but only if you voluntarily
            provide it to us. This may occur, for example, if you sign up to
            receive an email newsletter.&nbsp; We will use your email address
            for the purposes for which you provided it to us, and also from time
            to time to send you emails regarding the Site or other products or
            services that we believe may be of interest to you. &nbsp;You may
            opt out of such email communications at any time by clicking the
            &ldquo;unsubscribe&rdquo; button in the email.
          </p>

          <p>We will not share your email address with any third parties.</p>

          <p>
            If you are a resident of a country in the European Economic Area
            (EEA), please refer to the section below entitled &ldquo;Additional
            Rights of EEA Residents.&rdquo;
          </p>

          <p>&nbsp;</p>

          <p>
            <strong>Contact Forms</strong>
          </p>

          <p>
            If you contact us using our contact form, you will voluntarily
            provide your name and email address. This is only so we may reply to
            your contact request. We do not use this information for marketing
            purposes and you will not be emailed for any other purpose other
            than for us to respond to your email to us.
          </p>

          <p>
            <strong>
              Additional Rights of EEA (European Economic Area) Residents
            </strong>
          </p>

          <p>
            If you are a resident of a country in the EEA, you have the rights,
            among others, to:
          </p>

          <p>(i) &nbsp;access your personal data</p>

          <p>(ii) &nbsp;ensure the accuracy of your personal data</p>

          <p>(iii) the right to have us delete your personal data</p>

          <p>
            (iv) &nbsp;the right to restrict further processing of your personal
            data, and
          </p>

          <p>
            (v) &nbsp;the right to complain to a supervisory authority in your
            country of residence in the event that data is misused
          </p>

          <p>
            If you believe that our processing of your personal information
            infringes data protection laws, you have a legal right to lodge a
            complaint with a supervisory authority responsible for data
            protection. You may do so in the EU member state of your residence,
            your place of work or the place of the alleged infringement.
          </p>

          <p>
            Among other means that you may provide us for our communicating with
            you, you consent and agree to communicate with us via email.
          </p>

          <p>
            You may exercise any of your rights in relation to your personal
            data by contacting us at&nbsp;daniel@lifeofmarrow.com
          </p>

          <p>
            <strong>Changes to the Privacy Policy</strong>
          </p>

          <p>
            We may change this Privacy Policy from time to time. The most recent
            version of the Privacy Policy will always be posted on the Site,
            with the &ldquo;Effective Date&rdquo; posted at the top of the
            Policy. We may revise and update this Privacy Policy if our
            practices change, as technology changes, or as we add new services
            or change existing ones. If we make any material changes to our
            Privacy Policy or how we handle your personal information, or we are
            going to use any personal information in a manner that is materially
            different from that stated in our Privacy Policy at the time we
            collected such information, we will give you a reasonable
            opportunity to consent to the change. If you do not consent, your
            personal information will be used as agreed to under the terms of
            the privacy policy in effect at the time we obtained that
            information. By using our Site or services after the Effective Date,
            you are deemed to consent to our then-current privacy policy. We
            will use information previously obtained in accordance with the
            Privacy Policy in effect when the information was obtained from you.
          </p>

          <p>
            <strong>Agreeing to Terms</strong>
          </p>

          <p>
            If you do not agree to this Privacy Policy as posted here on this
            website, please do not use this site or any services offered by this
            site. Your use of this site indicates acceptance of this privacy
            policy.
          </p>

          <p>
            <strong>Contacting Us</strong>
          </p>

          <p>
            If you have any questions about this Privacy Policy, or the
            practices of this Site, please contact us
            at&nbsp;daniel@lifeofmarrow.com
          </p>

          <p>&nbsp;</p>

          <p>
            <strong>DISCLAIMER</strong>
          </p>

          <p>
            While the information contained within the site is periodically
            updated, no guarantee is given that the information provided in this
            website is correct, complete, and/or up-to-date.
          </p>

          <p>
            Permission is given for the downloading and temporary storage of one
            or more of these pages for the purpose of viewing on a personal
            computer. The contents of this site are protected by copyright under
            international conventions and, apart from the permission stated, the
            reproduction, permanent storage, or retransmission of the contents
            of this site is prohibited without the prior written consent of
            lifeofmarrow.com
          </p>

          <p>
            Some links within this website may lead to other websites, including
            those operated and maintained by third parties. lifeofmarrow.com
            includes these links solely as a convenience to you, and the
            presence of such a link does not imply a responsibility for the
            linked site or an endorsement of the linked site, its operator, or
            its contents (exceptions may apply).
          </p>

          <p>
            This website and its contents are provided &ldquo;AS IS&rdquo;
            without warranty of any kind, either express or implied, including,
            but not limited to, the implied warranties of merchantability,
            fitness for a particular purpose, or non-infringement.
          </p>

          <p>
            Reproduction, distribution, republication, and/or retransmission of
            material contained within this website are prohibited unless the
            prior written permission of lifeofmarrow.com has been obtained.
          </p>

          <p>&nbsp;</p>

          <p>
            <strong>Nutritional Information Disclaimer</strong>
          </p>

          <p>
            Nutritional information is provided on the recipes as a courtesy
            only and should not be construed as a guarantee. This nutritional
            information is gathered from online calorie calculators such as
            cronometer.com,{" "}
            <a href="https://nutritiondata.self.com">
              https://nutritiondata.self.com
            </a>{" "}
            etc&hellip; and as such should be considered estimates.
          </p>

          <p>&nbsp;</p>

          <p>
            This content is strictly the opinion of marrowhealth and is for
            informational and educational purposes only. It is not intended to
            provide medical advice or to take the place of medical advice or
            treatment from a doctor. All readers/viewers of this content are
            advised to consult their doctors or qualified health professionals
            regarding specific health questions. lifeofmarrow.com does not take
            responsibility for possible health consequences of any person or
            persons reading or following the information in this educational
            content. All viewers of this content, especially those taking
            prescription or over-the-counter medications, should consult their
            dpctors before beginning any nutrition, supplement or lifestyle
            program.
          </p>
        </Typography>
      </Container>
    </React.Fragment>
  );
};
