import {
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Grid,
  Typography
} from "@material-ui/core";
import { format } from "date-fns";
import * as React from "react";
import { Link } from "react-router-dom";
import { isMobile } from "../../../AppLayout";
import { Loader } from "../../../components";
import { imageUrl, parseGQLDate } from "../../../lib";
import { blogFields } from "../../../lib/ApolloClient/Fragments/types/blogFields";

interface Props {
  blogPost: blogFields;
}

export const BlogTile: React.FC<Props> = ({
  blogPost: { image_url, published_on, title }
}) => {
  const [loaded, setLoaded] = React.useState(false);

  return (
    <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
      <Link
        to={{ pathname: "/blog", search: `?title=${title}` }}
        style={{ textDecoration: "none" }}
      >
        <Card style={{ margin: "auto", width: !isMobile ? 375 : "100%" }}>
          <CardActionArea>
            <CardMedia
              style={{
                height: !isMobile ? 375 : 343
              }}
              image={loaded ? imageUrl(image_url || "") : "."}
              title={title}
            >
              {!loaded && (
                <Loader
                  imageSrc={imageUrl(image_url || "")}
                  onImgLoad={e => {
                    setLoaded(true);
                  }}
                />
              )}
            </CardMedia>
            <CardHeader
              title={
                <Typography variant="h3" color="primary">
                  {title}
                </Typography>
              }
              subheader={
                <Typography variant="caption">
                  Published on{" "}
                  {format(parseGQLDate(published_on), "dd MMM yyyy")}
                </Typography>
              }
            />
          </CardActionArea>
        </Card>
      </Link>
    </Grid>
  );
};
