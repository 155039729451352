import { Typography } from "@material-ui/core";
import { loader } from "graphql.macro";
import * as React from "react";
import { useQuery } from "react-apollo";
import { useLocation } from "react-router-dom";
import { HEADER_PADDING, isMobile } from "../../AppLayout";
import { BackButton, Loader } from "../../components";
import { capitalise, getPath } from "../../lib";
import { RecipePage, RecipeSearch } from "./components";
import { RecipeQuery, RecipeQueryVariables } from "./types/RecipeQuery";

const query = loader("./Query.graphql");

interface Props {}

export const Recipe: React.FC<Props> = () => {
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);
  const selectedRecipe =
    urlSearchParams.get("name") || getPath(location.pathname);

  const { data, loading } = useQuery<RecipeQuery, RecipeQueryVariables>(query, {
    skip: !selectedRecipe,
    variables: { name: selectedRecipe }
  });

  if (data && data.recipe) {
    document.title = `Marrow - ${capitalise(data.recipe.name)}`;
  } else {
    document.title = "Marrow - Recipes";
  }

  return (
    <React.Fragment>
      {loading || !data || (!data.recipe && <Loader />)}

      <Typography
        variant="h1"
        color="primary"
        align={isMobile ? "center" : "left"}
      >
        {data && data.recipe ? data.recipe.name : "Recipes"}
      </Typography>

      <div style={{ paddingTop: HEADER_PADDING / 2 }}>
        {data && data.recipe ? (
          <React.Fragment>
            <RecipePage recipe={data.recipe} />

            <BackButton />
          </React.Fragment>
        ) : (
          <RecipeSearch />
        )}
      </div>
    </React.Fragment>
  );
};
