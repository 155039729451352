import { Grid } from "@material-ui/core";
import { loader } from "graphql.macro";
import * as React from "react";
import { Query } from "react-apollo";
import { FoodTile, Loader } from "../../../../components";
import { Pager, PAGE_SIZE } from "../../../../components/Pager";
import { FoodsQuery, FoodsQueryVariables } from "./types/FoodsQuery";

interface Props {
  search: string;
}

const query = loader("./Query.graphql");

export const FoodSearch: React.FC<Props> = ({ search }) => {
  const [currentPage, setCurrentPage] = React.useState(1);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  return (
    <Query<FoodsQuery, FoodsQueryVariables>
      query={query}
      variables={{ query: search, page: currentPage, pageSize: PAGE_SIZE }}
    >
      {({ loading, data }) => (
        <React.Fragment>
          {loading || !data || !data.foods ? (
            <Loader />
          ) : (
            <React.Fragment>
              <Grid container spacing={3} justifyContent="flex-start">
                {data.foods.foods.map((f, i) => (
                  <Grid item xl={2} lg={3} md={4} sm={4} xs={12} key={i}>
                    <FoodTile food={f} />
                  </Grid>
                ))}
              </Grid>

              <br />

              <Pager
                totalItems={data.foods.total}
                currentPage={currentPage}
                setCurrentPage={page => setCurrentPage(page)}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Query>
  );
};
