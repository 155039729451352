import { Avatar, Chip, Grid } from "@material-ui/core";
import {
  BarleyOff,
  ChiliMild,
  Food,
  FoodVariant,
  TemperatureCelsius,
} from "mdi-material-ui";
import * as React from "react";
import { Link } from "react-router-dom";
import {
  RecipeQuery_recipe_cuisine,
  RecipeQuery_recipe_mealTypes,
  RecipeQuery_recipe_requirements,
  RecipeQuery_recipe_taste,
  RecipeQuery_recipe_temperature,
} from "../../types/RecipeQuery";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

interface Props {
  taste: RecipeQuery_recipe_taste;
  temperature: RecipeQuery_recipe_temperature;
  cuisine: RecipeQuery_recipe_cuisine;
  mealTypes: RecipeQuery_recipe_mealTypes[] | null;
  requirements: RecipeQuery_recipe_requirements[] | null;
}

interface Info {
  search: string;
  label: string;
  icon: React.ComponentType<SvgIconProps>;
}

export const RecipeInfo: React.FC<Props> = ({
  taste,
  temperature,
  cuisine,
  mealTypes,
  requirements,
}) => {
  const chipStyles = makeStyles(() => ({
    colorDefault: { color: "#fff", backgroundColor: "rgb(0, 107, 71)" },
    root: {
      width: "32px",
      height: "32px",
      fontSize: "1rem",
      marginRight: "-4px",
    },
  }))();

  const info: Info[] = [];

  if (taste) {
    info.push({
      search: `?taste=${taste.name}`,
      label: taste.name,
      icon: ChiliMild,
    });
  }

  if (temperature) {
    info.push({
      search: `?temperature=${temperature.name}`,
      label: temperature.name,
      icon: TemperatureCelsius,
    });
  }

  if (cuisine) {
    info.push({
      search: `?cuisine=${cuisine.name}`,
      label: cuisine.name,
      icon: FoodVariant,
    });
  }

  if (mealTypes) {
    info.push(
      ...mealTypes.map<Info>((mt) => ({
        search: `?type=${mt.name}`,
        label: mt.name,
        icon: Food,
      }))
    );
  }

  if (requirements) {
    info.push(
      ...requirements.map<Info>((r) => ({
        search: `?requirements=${r.name}`,
        label: r.name,
        icon: BarleyOff,
      }))
    );
  }

  return (
    <React.Fragment>
      {info.map(({ icon: Icon, search, label }, i) => (
        <Grid item key={i}>
          <Link
            to={{ pathname: "/recipes", search }}
            style={{ textDecoration: "none" }}
          >
            <Chip
              color="primary"
              style={{ cursor: "pointer" }}
              label={label}
              avatar={
                <Avatar classes={chipStyles}>
                  <Icon />
                </Avatar>
              }
            />
          </Link>
        </Grid>
      ))}
    </React.Fragment>
  );
};
