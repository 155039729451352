import {
  Card,
  CardMedia,
  Grid,
  TextField,
  Theme,
  Typography
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import * as React from "react";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import { HEADER_PADDING, isMobile } from "../../../../AppLayout";
import { Loader } from "../../../../components";
import { imageUrl, toUrl } from "../../../../lib";
import {
  RecipeQuery_recipe,
  RecipeQuery_recipe_ingredients
} from "../../types/RecipeQuery";
import { RecipeInfo } from "./RecipeInfo";
import { useStyles } from "./style";

interface Props {
  recipe: RecipeQuery_recipe;
}

export const RecipePage: React.FC<Props> = ({
  recipe: {
    cooking_time,
    cuisine,
    difficulty,
    image_url,
    ingredientNote,
    ingredients,
    mealTypes,
    intro,
    method,
    name,
    preparation_time,
    requirements,
    servings,
    taste,
    temperature,
    total_time
  }
}) => {
  const theme = useTheme<Theme>();
  const [imageWidth, setImageWidth] = React.useState(0);
  const imageRef = React.useRef<HTMLBaseElement>(null);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setImageWidth(imageRef.current ? imageRef.current.clientWidth : 0);
  }, [loaded]);

  const classes = useStyles();

  const ingredientGroups =
    ingredients &&
    ingredients.reduce<
      Array<{
        group: string | null;
        ingredients: RecipeQuery_recipe_ingredients[];
      }>
    >((groups, ing) => {
      const group = groups.find(g => g.group === ing.group);

      if (!group) {
        groups.push({
          group: ing.group,
          ingredients: [ing]
        });
      } else {
        group.ingredients.push(ing);
      }

      return groups;
    }, []);

  const restItem = ingredientGroups?.find(
    ig => ig.group?.toUpperCase() === "THE REST"
  );

  if (restItem) {
    ingredientGroups?.push(restItem);
    ingredientGroups?.splice(ingredientGroups.indexOf(restItem), 1);
  }

  const recipeStats = (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography variant="h2" align={"center"}>
          Info
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography align="right">
          <b>Difficulty</b>
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography>{difficulty.name}</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography align="right">
          <b>Preparation Time</b>
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography>{preparation_time} mins</Typography>
      </Grid>

      {cooking_time && parseInt(cooking_time, 10) > 0 && (
        <React.Fragment>
          <Grid item xs={6}>
            <Typography align="right">
              <b>Cooking Time</b>
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography>{cooking_time} mins</Typography>
          </Grid>
        </React.Fragment>
      )}

      <Grid item xs={6}>
        <Typography align="right">
          <b>Servings</b>
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField type="number" name="servings" value={servings} />
      </Grid>
    </React.Fragment>
  );

  const recipeIngredients = (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography variant="h2" align={"center"}>
          Ingredients
        </Typography>
      </Grid>

      {ingredientGroups &&
        ingredientGroups.map((g, i) => (
          <React.Fragment key={i}>
            {g.group && (
              <React.Fragment>
                <Grid item xs={12}>
                  <Typography align="center" variant="h4" color="textPrimary">
                    <b>{g.group}</b>
                  </Typography>
                </Grid>
              </React.Fragment>
            )}

            {g.ingredients.map(ing => (
              <React.Fragment key={ing.id}>
                <Grid item xs={6}>
                  <Typography align="right">
                    <b>
                      {ing.friendlyValue || ing.value}{" "}
                      {ing.friendlyUnit || ing.unit.abbrev}
                    </b>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Link
                    to={{
                      pathname: `/food/${toUrl(ing.food.name)}`
                    }}
                    style={{
                      textDecoration: "none",
                      color: theme.palette.primary.main
                    }}
                  >
                    <Typography>{ing.food.name}</Typography>
                  </Link>
                </Grid>
              </React.Fragment>
            ))}

            <Grid item xs={12} />
          </React.Fragment>
        ))}

      <Grid item xs={12}>
        <Typography
          variant="body1"
          align="center"
          style={{ fontStyle: "italic" }}
        >
          {ingredientNote}
        </Typography>
      </Grid>
    </React.Fragment>
  );

  const recipeIntro = (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography variant="h2" align={isMobile ? "center" : "left"}>
          Intro
        </Typography>
      </Grid>

      <Typography component="div" className={classes.method}>
        {renderHTML(intro)}
      </Typography>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {/* <Helmet>
        <meta property="og:site_name" content={`${name} - Marrow`} />
        <meta
          property="og:url"
          content={`https://lifeofmarrow.com/recipe?name=${name}`}
        />
        <meta property="og:image" content={imageUrl(image_url || "")} />
      </Helmet> */}

      <Grid
        container
        spacing={3}
        justify={isMobile ? "center" : "space-around"}
      >
        <Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
          {!loaded ? (
            <Loader
              imageSrc={imageUrl(image_url || "")}
              onImgLoad={e => setLoaded(true)}
            />
          ) : (
            <Card
              ref={imageRef}
              style={{
                height: imageWidth,
                margin: isMobile ? "auto" : "unset"
              }}
            >
              <CardMedia
                image={imageUrl(image_url || "")}
                style={{
                  height: imageWidth,
                  backgroundSize: "contain",
                  backgroundPosition: isMobile ? "center" : "left top"
                }}
              />
            </Card>
          )}

          <Grid
            container
            spacing={1}
            style={{ paddingTop: HEADER_PADDING / 2 }}
            justify={isMobile ? "center" : "flex-start"}
          >
            <RecipeInfo
              cuisine={cuisine}
              mealTypes={mealTypes}
              requirements={requirements}
              taste={taste}
              temperature={temperature}
            />
          </Grid>
        </Grid>

        {isMobile && (
          <React.Fragment>
            {recipeStats}

            {recipeIngredients}
          </React.Fragment>
        )}

        <Grid item xl={6} lg={5} md={4} sm={6} xs={12}>
          {intro && recipeIntro}

          <Typography variant="h2" align={isMobile ? "center" : "left"}>
            Method
          </Typography>

          <Typography component="div" className={classes.method}>
            {renderHTML(method)}
          </Typography>
        </Grid>

        <Grid container spacing={2} item xl={3} lg={4} md={3} sm={6} xs={12}>
          {!isMobile && recipeStats}

          <br />

          {!isMobile && recipeIngredients}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
