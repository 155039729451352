import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  method: {
    textAlign: "justify",
    "& ol li": {
      padding: 8
    }
  }
}));
