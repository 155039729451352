import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme>(theme => ({
  letter: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    margin: `${theme.spacing(1) / 2}px`,
    textDecoration: "none",
    display: "inline-flex",
    width: 25,
    height: 25,
    fontSize: 12
  },

  selected: {
    backgroundColor: theme.palette.primary.main,
    color: "white"
  }
}));
