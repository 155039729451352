import { Fab, List, ListItem, ListItemText, Theme, Zoom } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useTheme } from "@material-ui/styles";
import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { isMobile, MarrowRoutes } from "../../AppLayout";
import { imageUrl } from "../../lib";
import { useStyles } from "./style";

interface Props extends RouteComponentProps {
  isHomepage?: boolean;
}

export const Navigation: React.FC<Props> = ({
  isHomepage,
  history: { location }
}) => {
  const theme = useTheme<Theme>();
  const classes = useStyles(theme);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const visibleRoutes = MarrowRoutes.filter(r => !r.hidden);

  return (
    <div
      className={`${classes.topbar} ${isHomepage ? "home" : ""}`}
      style={isMobile ? { display: "flex", justifyContent: "center" } : {}}
    >
      <Link to={"/"}>
        {isMobile && !isHomepage ? (
          <img
            src={imageUrl("small")}
            alt="Marrow Logo"
            style={{ height: 130 }}
          />
        ) : (
          <img src="/img/logo.png" alt="Marrow Logo" style={{ height: 130 }} />
        )}
      </Link>

      {isMobile ? (
        !isHomepage && (
          <React.Fragment>
            <Fab
              color="primary"
              aria-label="Menu"
              className={classes.mobileMenu}
              onClick={e => setMenuOpen(prevMenuOpen => !prevMenuOpen)}
            >
              <MenuIcon />
            </Fab>

            <Zoom in={menuOpen} style={{ transformOrigin: "top right" }}>
              <List
                className={`${classes.mobileMenu} ${classes.mobileMenuExpanded}`}
                component="nav"
                aria-label="Expanded Menu"
              >
                {visibleRoutes.map(({ path, label }, i) => (
                  <ListItem
                    className={classes.mobileMenuItem}
                    key={i}
                    button
                    component="a"
                    href={path}
                  >
                    <ListItemText primary={label} />
                  </ListItem>
                ))}
              </List>
            </Zoom>
          </React.Fragment>
        )
      ) : (
        <ul className={classes.navigation}>
          {visibleRoutes.map(({ path, label }, i) => (
            <li key={i}>
              <Link
                to={path}
                className={`${classes.menuItem} ${
                  isHomepage ? "home" : "notHome"
                }`}
                style={{
                  color:
                    location.pathname === path ? theme.palette.primary.main : ""
                }}
              >
                {label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
