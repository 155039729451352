import { Grid, Typography } from "@material-ui/core";
import { loader } from "graphql.macro";
import * as React from "react";
import { useQuery } from "react-apollo";
import { useLocation } from "react-router";
import { isMobile } from "../../AppLayout";
import { Loader } from "../../components";
import { getPath } from "../../lib";
import { FoodIndex, FoodPage } from "./components";
import { FoodSearch } from "./components/FoodSearch";
import { FoodQuery, FoodQueryVariables } from "./types/FoodQuery";

const query = loader("./Query.graphql");

interface Props {}

export const Food: React.FC<Props> = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  const selectedLetter = urlSearchParams.get("letter");
  const search = urlSearchParams.get("search");
  const selectedFood =
    urlSearchParams.get("name") || getPath(location.pathname);

  const { data, loading } = useQuery<FoodQuery, FoodQueryVariables>(query, {
    skip: !selectedFood,
    variables: { name: selectedFood! }
  });

  const foodIndex = (
    <Grid item md={2}>
      <FoodIndex
        selectedLetter={
          (selectedLetter && selectedLetter.substr(0, 1).toUpperCase()) ||
          undefined
        }
      />
    </Grid>
  );

  return (
    <Grid container spacing={3}>
      <Grid item md={10} xs={12}>
        {selectedFood ? (
          loading || !data ? (
            <Loader />
          ) : (
            <FoodPage food={data.food} />
          )
        ) : !search && !selectedLetter ? (
          <Typography variant="h2" align={isMobile ? "center" : "left"}>
            No food selected, pick a letter from the index to start!
          </Typography>
        ) : (
          <FoodSearch search={search || selectedLetter || ""} />
        )}
      </Grid>

      {(!isMobile || !selectedFood) && foodIndex}
    </Grid>
  );
};
