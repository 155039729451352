import { Theme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/styles";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Blog, Food, Homepage, Legal, Recipe } from "./views";
import { Store } from "./views/Store";

interface Props extends RouteComponentProps {
  children: (
    isHomepage: boolean,
    routeProps: RouteComponentProps
  ) => React.ReactNode;
}

export const MarrowRoutes = [
  { exact: true, path: "/", component: Homepage, label: "Home" },
  { path: "/recipes", component: Recipe, label: "Recipes" },
  {
    path: "/store",
    component: Store,
    label: "Store",
  },
  { path: "/food", component: Food, label: "Food" },
  { path: "/blog", component: Blog, label: "Blog" },
  {
    path: "/disclaimer",
    component: Legal,
    label: "Privacy Policy & Disclaimer",
    hidden: true,
  },
];

export const HEADER_PADDING = 50;

export let isMobile = false;

const AppLayoutComponent: React.FC<Props> = ({ children, ...routeProps }) => {
  const theme = useTheme<Theme>();
  isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { location } = routeProps;
  const isHomepage = location.pathname === "/";
  const documentName = MarrowRoutes.find((r) => r.path === location.pathname);

  document.title =
    (documentName && `${documentName.label} | Marrow`) || "Marrow";

  return <React.Fragment>{children(isHomepage, routeProps)}</React.Fragment>;
};

export const AppLayout = withRouter((props: Props) => (
  <AppLayoutComponent {...props} />
));
