import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { HEADER_PADDING } from "../../AppLayout";

export const useStyles = makeStyles<Theme>(theme => ({
  loader: {
    width: "100%",
    textAlign: "center",
    paddingTop: HEADER_PADDING
  }
}));
