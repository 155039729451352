import { Fade } from "@material-ui/core";
import { loader } from "graphql.macro";
import * as React from "react";
import { Query } from "react-apollo";
import { RouteComponentProps } from "react-router";
import { Loader } from "../../components";
import { imageUrl } from "../../lib";
import { Featured, Splash } from "./components";
import { HomepageImages } from "./types/HomepageImages";

interface Props extends RouteComponentProps {}

const homepageImageQuery = loader("./HomepageImageQuery.graphql");

export const Homepage: React.FC<Props> = ({ ...routeProps }) => {
  const [loaded, setLoaded] = React.useState(false);
  const [scrolled, setScolled] = React.useState(false);

  const { location } = routeProps;

  React.useEffect(() => {
    if (location.hash === "#featured") {
      document.title = "Featured - Marrow";
    } else {
      document.title = "Marrow";
    }
  }, [location.hash]);

  return (
    <Query<HomepageImages> query={homepageImageQuery}>
      {({ data, loading }) => {
        if (loading || !data || !Array.isArray(data.homepageImages.images)) {
          return <Loader />;
        }

        const backgroundUrl = imageUrl(data.homepageImages.images[0]);

        if (!loaded) {
          return (
            <Loader
              imageSrc={backgroundUrl}
              onImgLoad={e => {
                setLoaded(true);
              }}
            />
          );
        }

        return (
          <React.Fragment>
            {!scrolled ? (
              <Fade in>
                <Splash
                  backgroundUrl={backgroundUrl}
                  setScrolled={scrolled => setScolled(scrolled)}
                  {...routeProps}
                />
              </Fade>
            ) : (
              <Featured scrolled={scrolled} {...routeProps} />
            )}
          </React.Fragment>
        );
      }}
    </Query>
  );
};
