import { CircularProgress, Theme } from "@material-ui/core";
import { CircularProgressProps } from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/styles";
import * as React from "react";
import { useStyles } from "./style";

interface Props extends CircularProgressProps {
  imageSrc?: string;
  onImgLoad?(e: React.SyntheticEvent<HTMLImageElement, Event>): void;
}

export const Loader: React.FC<Props> = ({
  imageSrc,
  onImgLoad,
  ...progressProps
}) => {
  const theme = useTheme<Theme>();
  const classes = useStyles(theme);

  return (
    <div className={classes.loader}>
      <CircularProgress variant="indeterminate" {...progressProps} />
      {imageSrc && (
        <img
          alt="loading"
          style={{ display: "hidden", height: 0, width: 0 }}
          src={imageSrc}
          onLoad={onImgLoad}
        />
      )}
    </div>
  );
};
