import { Typography } from "@material-ui/core";
import { loader } from "graphql.macro";
import * as React from "react";
import { Query } from "react-apollo";
import { useLocation } from "react-router";
import { isMobile } from "../../AppLayout";
import { BackButton, Loader } from "../../components";
import { BlogPost, BlogSearch } from "./components";
import { BlogPostQuery, BlogPostQueryVariables } from "./types/BlogPostQuery";

interface Props {}

const query = loader("./Query.graphql");

export const Blog: React.FC<Props> = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const selectedPost = urlSearchParams.get("title");
  const selectedMonth = urlSearchParams.get("month");
  const selectedYear = urlSearchParams.get("year");
  const selectedCategory = urlSearchParams.get("category");
  const search = urlSearchParams.get("search");

  let filterDate: Date | null = null;
  const parsedYear = parseInt(selectedYear || "", 10);
  const parsedMonth = parseInt(selectedMonth || "", 10);

  if (!isNaN(parsedMonth) && !isNaN(parsedYear)) {
    filterDate = new Date(parsedYear, parsedMonth);
  }

  return (
    <React.Fragment>
      <Typography
        variant="h1"
        color="primary"
        align={isMobile ? "center" : "left"}
      >
        Blog
      </Typography>

      {!selectedPost ? (
        <BlogSearch
          categoryName={selectedCategory}
          filterDate={filterDate}
          search={search}
        />
      ) : (
        <Query<BlogPostQuery, BlogPostQueryVariables>
          query={query}
          variables={{ name: selectedPost }}
        >
          {({ loading, data, error }) => (
            <React.Fragment>
              {loading || (!data && <Loader />)}
              {data && data.blogpost && (
                <React.Fragment>
                  <BlogPost blogPost={data.blogpost} />

                  <BackButton />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Query>
      )}
    </React.Fragment>
  );
};
