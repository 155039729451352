import { Fab, Theme } from "@material-ui/core";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { useTheme } from "@material-ui/styles";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from "../../AppLayout";

interface Props {}

export const BackButton: React.FC<Props> = () => {
  const history = useHistory();
  const theme = useTheme<Theme>();

  return isMobile ? (
    <Fab
      aria-label="Back"
      color="primary"
      onClick={e => history.goBack()}
      variant="extended"
      style={{
        position: "fixed",
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 2
      }}
    >
      <ArrowBackIos alignmentBaseline="middle" />
      Back
    </Fab>
  ) : null;
};
