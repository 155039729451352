import { parseISO } from "date-fns";

export function parseGQLDate(d: Object): Date {
  if (d.hasOwnProperty("length")) {
    return parseISO(d.toString());
  } else {
    return d as Date;
  }
}

export function toUrl(s: string) {
  return s.toLowerCase().replace(/\s+/gm, "-");
}

export function capitalise(s: string) {
  return s
    .toLowerCase()
    .trim()
    .split("")
    .map((c, i, arr) =>
      (typeof arr[i - 1] === "undefined" || arr[i - 1] === " ") &&
      !["and", "is", "for"].includes(arr.slice(i, arr.indexOf(" ", i)).join(""))
        ? c.toUpperCase()
        : c.toLowerCase()
    )
    .join("");
}

export function getPath(pathName: string) {
  const sections = pathName.split("/").filter(Boolean);

  if (sections.length === 1) {
    return "";
  }

  return sections.reverse()[0];
}

export const apiUrl = "https://api.lifeofmarrow.com";
// export const apiUrl = "http://localhost:4000";
