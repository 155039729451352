import {
  Button,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  Theme,
  Typography,
  Zoom,
  TextField
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import * as React from "react";
import { isMobile } from "../../AppLayout";
import { usePrevious } from "../../hooks";

export type DropdownOption = {
  value: string;
  label: string;
};

interface Props {
  label: string;
  options: DropdownOption[];
  multiple?: boolean;
  selectedName?: string;

  dropdownOpen: boolean;

  setDropdownOpen(open: boolean): void;
  setSelectedName(name: string | undefined): void;
  searchDropdown?(query: string): DropdownOption[];
}

export const Dropdown: React.FC<Props> = ({
  label,
  options,
  multiple,
  selectedName,
  setSelectedName,
  dropdownOpen,
  setDropdownOpen
}) => {
  const theme = useTheme<Theme>();
  const [searchText, setSearchText] = React.useState("");

  const prevDropdownOpen = usePrevious(dropdownOpen);
  React.useEffect(() => {
    if (!prevDropdownOpen && dropdownOpen) {
      setSearchText("");
    }
  }, [prevDropdownOpen, dropdownOpen]);

  const selectedItem =
    !multiple &&
    selectedName &&
    options.find(o => o.label.toLowerCase() === selectedName.toLowerCase());

  return (
    <ClickAwayListener onClickAway={e => setDropdownOpen(false)}>
      <React.Fragment>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{ width: "100%" }}
          onClick={e => setDropdownOpen(!dropdownOpen)}
        >
          {selectedItem ? selectedItem.label : label}
          &#9660;
        </Button>

        {dropdownOpen && (
          <Zoom in={dropdownOpen} style={{ transformOrigin: "0 0 0" }}>
            <List
              style={{
                backgroundColor: theme.palette.primary.main,
                maxHeight: 350,
                overflowY: "auto",
                zIndex: 99,
                position: isMobile ? "unset" : "absolute"
              }}
            >
              {multiple ? (
                <ListItem>
                  <TextField
                    name="search"
                    placeholder="Search"
                    label=""
                    fullWidth
                    value={searchText}
                    onChange={({
                      target
                    }: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchText(target.value)
                    }
                    inputProps={{
                      style: {
                        textAlign: isMobile ? "center" : "left",
                        color: "white"
                      }
                    }}
                  />
                </ListItem>
              ) : (
                selectedName && (
                  <ListItem
                    button
                    key={-1}
                    onClick={e => {
                      setSelectedName(undefined);
                      setDropdownOpen(false);
                    }}
                  >
                    <Typography
                      color="secondary"
                      style={{ width: "100%" }}
                      align={isMobile ? "center" : "left"}
                    >
                      Clear Selection
                    </Typography>
                  </ListItem>
                )
              )}

              <Divider />

              {options
                .filter(
                  i =>
                    !searchText ||
                    i.label
                      .toLocaleLowerCase()
                      .includes(searchText.toLocaleLowerCase())
                )
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((o, i) => (
                  <ListItem
                    button
                    key={i}
                    onClick={e => {
                      setSelectedName(o.label);
                      setDropdownOpen(false);
                    }}
                  >
                    <Typography
                      color="secondary"
                      style={{ width: "100%" }}
                      align={isMobile ? "center" : "left"}
                    >
                      {o.label}
                    </Typography>
                  </ListItem>
                ))}
            </List>
          </Zoom>
        )}
      </React.Fragment>
    </ClickAwayListener>
  );
};
