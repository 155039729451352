import {
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Typography
} from "@material-ui/core";
import * as React from "react";
import { Link } from "react-router-dom";
import { Loader } from "../../../../../components";
import { imageUrl, toUrl } from "../../../../../lib";
import { RecipesQuery_recipes_recipes } from "../types/RecipesQuery";

interface Props {
  recipe: RecipesQuery_recipes_recipes;
}

export const RecipeTile: React.FC<Props> = ({ recipe }) => {
  const [loaded, setLoaded] = React.useState(false);
  const [imageWidth, setImageWidth] = React.useState(0);
  const imageRef = React.useRef<HTMLBaseElement>(null);

  React.useEffect(() => {
    setImageWidth(imageRef.current ? imageRef.current.clientWidth : 0);
  }, [loaded]);

  return (
    <React.Fragment>
      <Link
        to={{
          pathname: `/recipes/${toUrl(recipe.name)}`
        }}
        style={{ textDecoration: "none" }}
      >
        <Card ref={imageRef} style={{ margin: "auto", width: "100%" }}>
          <CardActionArea>
            <CardMedia
              style={{
                height: imageWidth
              }}
              image={loaded ? imageUrl(recipe.image_url || "") : "."}
              title={recipe.name}
            >
              {!loaded && (
                <Loader
                  imageSrc={imageUrl(recipe.image_url || "")}
                  onImgLoad={e => {
                    setLoaded(true);
                  }}
                />
              )}
            </CardMedia>
            <CardHeader
              title={
                <Typography variant="h3" color="primary" align="center">
                  {recipe.name}
                </Typography>
              }
            />
          </CardActionArea>
        </Card>
      </Link>
    </React.Fragment>
  );
};
