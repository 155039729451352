import {
  Chip,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { loader } from "graphql.macro";
import * as React from "react";
import { Query } from "react-apollo";
import { useHistory, useLocation } from "react-router-dom";
import { HEADER_PADDING, isMobile } from "../../../../AppLayout";
import { Dropdown, EmptyView, Loader } from "../../../../components";
import { Pager, PAGE_SIZE } from "../../../../components/Pager";
import { RecipeTile } from "./RecipeTile";
import { useStyles } from "./style";
import { FilteringQuery } from "./types/FilteringQuery";
import { RecipesQuery, RecipesQueryVariables } from "./types/RecipesQuery";

const filtersGQL = loader("./FilteringQuery.graphql");
export const recipesGQL = loader("./Query.graphql");

interface Props {}

interface DropdownState {
  tastesOpen: boolean;
  temperaturesOpen: boolean;
  typesOpen: boolean;
  cuisinesOpen: boolean;
  requirementsOpen: boolean;
  foodsOpen: boolean;
  timesOpen: boolean;
}

enum Dropdowns {
  TASTES = "taste",
  TEMPERATURES = "temperature",
  TYPES = "type",
  CUISINES = "cuisine",
  REQUIREMENTS = "requirements",
  FOODS = "foods",
  TIMES = "times"
}

const dropdownInitialState: DropdownState = {
  tastesOpen: false,
  temperaturesOpen: false,
  typesOpen: false,
  cuisinesOpen: false,
  requirementsOpen: false,
  foodsOpen: false,
  timesOpen: false
};

const dropdownStateReducer = (
  state: DropdownState,
  action: { type: Dropdowns; value: boolean }
) => {
  const newState = { ...state, ...dropdownInitialState };

  switch (action.type) {
    case Dropdowns.TASTES: {
      newState.tastesOpen = action.value;
      break;
    }

    case Dropdowns.TEMPERATURES: {
      newState.temperaturesOpen = action.value;
      break;
    }

    case Dropdowns.TYPES: {
      newState.typesOpen = action.value;
      break;
    }

    case Dropdowns.CUISINES: {
      newState.cuisinesOpen = action.value;
      break;
    }

    case Dropdowns.REQUIREMENTS: {
      newState.requirementsOpen = action.value;
      break;
    }

    case Dropdowns.FOODS: {
      newState.foodsOpen = action.value;
      break;
    }

    case Dropdowns.TIMES: {
      newState.timesOpen = action.value;
      break;
    }
  }

  return newState;
};

const cookingTimes = [
  { label: "Under 15 mins", value: 15 },
  { label: "Under 30 mins", value: 30 }
];

export const RecipeSearch: React.FC<Props> = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [queryState, setQueryState] = React.useState("");
  const [dropdownState, dispatchDropdownState] = React.useReducer(
    dropdownStateReducer,
    dropdownInitialState
  );

  const urlSearchParams = new URLSearchParams(location.search);

  const selectedTaste = urlSearchParams.get(Dropdowns.TASTES) || undefined;
  const selectedCuisine = urlSearchParams.get(Dropdowns.CUISINES) || undefined;
  const selectedTime = urlSearchParams.get(Dropdowns.TIMES) || undefined;
  const selectedTemperature =
    urlSearchParams.get(Dropdowns.TEMPERATURES) || undefined;
  const selectedType = urlSearchParams.get(Dropdowns.TYPES) || undefined;
  const selectedRequirements = urlSearchParams.get(Dropdowns.REQUIREMENTS);
  const selectedFoods = urlSearchParams.get(Dropdowns.FOODS);
  const query = urlSearchParams.get("query") || undefined;

  React.useEffect(() => {
    setCurrentPage(1);
  }, [
    selectedTaste,
    selectedCuisine,
    selectedTime,
    selectedTemperature,
    selectedType,
    selectedTemperature,
    selectedType,
    selectedRequirements,
    selectedFoods,
    query
  ]);

  const setQueryString = (
    filter: Dropdowns | undefined,
    value: string | undefined,
    remove: boolean = false
  ) => {
    if (filter) {
      if (value) {
        if (
          [Dropdowns.FOODS, Dropdowns.REQUIREMENTS].includes(filter) &&
          urlSearchParams.has(filter)
        ) {
          const existingParams = urlSearchParams.get(filter)!.split(",");

          if (!remove) {
            if (!existingParams.includes(value)) {
              existingParams.push(value);
            }
          } else {
            const paramIdx = existingParams.findIndex(
              p => p.toLowerCase() === value.toLowerCase()
            );

            if (paramIdx !== -1) {
              existingParams.splice(paramIdx, 1);
            }
          }
          urlSearchParams.set(filter, existingParams.join(","));
        } else {
          urlSearchParams.set(filter, value);
        }
      } else {
        urlSearchParams.delete(filter);
      }
    } else {
      if (value) {
        urlSearchParams.set("query", value);
      } else {
        urlSearchParams.delete("query");
      }
    }

    history.push({ pathname: "/recipes", search: urlSearchParams.toString() });
  };

  return (
    <Query<FilteringQuery> query={filtersGQL}>
      {({ data, loading }) => {
        if (loading || !data) {
          return <Loader />;
        }

        const cuisine =
          selectedCuisine &&
          data.cuisines &&
          data.cuisines.find(
            c => c.name.toLowerCase() === selectedCuisine.toLowerCase()
          );

        const temperature =
          selectedTemperature &&
          data.temperatures &&
          data.temperatures.find(
            c => c.name.toLowerCase() === selectedTemperature.toLowerCase()
          );

        const type =
          selectedType &&
          data.mealtypes &&
          data.mealtypes.find(
            mt => mt.name.toLowerCase() === selectedType.toLowerCase()
          );

        const taste =
          selectedTaste &&
          data.tastes &&
          data.tastes.find(
            t => t.name.toLowerCase() === selectedTaste.toLowerCase()
          );

        const time =
          selectedTime && cookingTimes.find(ct => ct.label === selectedTime);

        const selectedRequirementsArray =
          selectedRequirements &&
          selectedRequirements.split(",").map(sr => sr.toLowerCase());

        const requirements =
          selectedRequirementsArray &&
          data.requirements &&
          data.requirements.filter(r =>
            selectedRequirementsArray.includes(r.name.toLowerCase())
          );

        const selectedFoodArray =
          selectedFoods && selectedFoods.split(",").map(sf => sf.toLowerCase());

        const foods =
          selectedFoodArray &&
          data.foods &&
          data.foods.foods.filter(f =>
            selectedFoodArray.includes(f.name.toLowerCase())
          );

        return (
          <Grid container justifyContent="center">
            <Grid container spacing={3} justifyContent="space-evenly">
              <Grid item lg={2} md={3} sm={6} xs={12}>
                {!data.tastes ? (
                  <Loader />
                ) : (
                  <Dropdown
                    label="Taste"
                    dropdownOpen={dropdownState.tastesOpen}
                    setDropdownOpen={open =>
                      dispatchDropdownState({
                        type: Dropdowns.TASTES,
                        value: open
                      })
                    }
                    options={data.tastes.map(t => ({
                      label: t.name,
                      value: t.id
                    }))}
                    selectedName={selectedTaste}
                    setSelectedName={name =>
                      setQueryString(Dropdowns.TASTES, name)
                    }
                  />
                )}
              </Grid>

              <Grid item lg md sm xs>
                {!data.temperatures ? (
                  <Loader />
                ) : (
                  <Dropdown
                    label="Temperature"
                    dropdownOpen={dropdownState.temperaturesOpen}
                    setDropdownOpen={open =>
                      dispatchDropdownState({
                        type: Dropdowns.TEMPERATURES,
                        value: open
                      })
                    }
                    options={data.temperatures.map(t => ({
                      label: t.name,
                      value: t.id
                    }))}
                    selectedName={selectedTemperature}
                    setSelectedName={name =>
                      setQueryString(Dropdowns.TEMPERATURES, name)
                    }
                  />
                )}
              </Grid>

              <Grid item lg md sm xs>
                {!data.mealtypes ? (
                  <Loader />
                ) : (
                  <Dropdown
                    label="Meal Type"
                    dropdownOpen={dropdownState.typesOpen}
                    setDropdownOpen={open =>
                      dispatchDropdownState({
                        type: Dropdowns.TYPES,
                        value: open
                      })
                    }
                    options={data.mealtypes.map(t => ({
                      label: t.name,
                      value: t.id
                    }))}
                    selectedName={selectedType}
                    setSelectedName={name =>
                      setQueryString(Dropdowns.TYPES, name)
                    }
                  />
                )}
              </Grid>

              <Grid item lg md sm xs>
                {!data.cuisines ? (
                  <Loader />
                ) : (
                  <Dropdown
                    label="Cuisine"
                    dropdownOpen={dropdownState.cuisinesOpen}
                    setDropdownOpen={open =>
                      dispatchDropdownState({
                        type: Dropdowns.CUISINES,
                        value: open
                      })
                    }
                    options={data.cuisines.map(t => ({
                      label: t.name,
                      value: t.id
                    }))}
                    selectedName={selectedCuisine}
                    setSelectedName={name =>
                      setQueryString(Dropdowns.CUISINES, name)
                    }
                  />
                )}
              </Grid>

              <Grid item lg md sm xs>
                <Dropdown
                  label="Total Time"
                  dropdownOpen={dropdownState.timesOpen}
                  setDropdownOpen={open =>
                    dispatchDropdownState({
                      type: Dropdowns.TIMES,
                      value: open
                    })
                  }
                  options={cookingTimes.map(({ value, ...rest }) => ({
                    value: value.toString(),
                    ...rest
                  }))}
                  selectedName={selectedTime}
                  setSelectedName={name =>
                    setQueryString(Dropdowns.TIMES, name)
                  }
                />
              </Grid>

              <Grid item md sm xs>
                {!data.requirements ? (
                  <Loader />
                ) : (
                  <Dropdown
                    label="Requirements"
                    multiple
                    dropdownOpen={dropdownState.requirementsOpen}
                    setDropdownOpen={open =>
                      dispatchDropdownState({
                        type: Dropdowns.REQUIREMENTS,
                        value: open
                      })
                    }
                    options={data.requirements.map(t => ({
                      label: t.name,
                      value: t.id
                    }))}
                    setSelectedName={name =>
                      setQueryString(Dropdowns.REQUIREMENTS, name)
                    }
                  />
                )}
              </Grid>

              <Grid item md sm xs>
                {!data.foods ? (
                  <Loader />
                ) : (
                  <Dropdown
                    label="Foods"
                    multiple
                    dropdownOpen={dropdownState.foodsOpen}
                    setDropdownOpen={open =>
                      dispatchDropdownState({
                        type: Dropdowns.FOODS,
                        value: open
                      })
                    }
                    options={data.foods.foods.map(t => ({
                      label: t.name,
                      value: t.id
                    }))}
                    setSelectedName={name =>
                      setQueryString(Dropdowns.FOODS, name)
                    }
                  />
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent="flex-end">
              <Grid item lg={4} md={6} sm={8} xs={12}>
                <TextField
                  fullWidth
                  color="primary"
                  placeholder="Search"
                  onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                    setQueryState(target.value)
                  }
                  style={{ paddingTop: HEADER_PADDING / 3 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    )
                  }}
                  onKeyPress={(e: React.KeyboardEvent) => {
                    if (e.charCode === 13) {
                      setQueryString(undefined, queryState);
                    }
                  }}
                />
              </Grid>
            </Grid>

            {selectedRequirements &&
              data.requirements &&
              selectedRequirements.split(",").map(sr => {
                const requirement = data.requirements!.find(
                  r => sr.toLowerCase() === r.name.toLowerCase()
                );

                if (!requirement) {
                  return null;
                }

                return (
                  <Chip
                    key={requirement.id}
                    color="primary"
                    label={
                      <Typography style={{ fontSize: "0.7rem" }}>
                        {requirement.name}
                      </Typography>
                    }
                    className={classes.filteringChip}
                    onDelete={() =>
                      setQueryString(
                        Dropdowns.REQUIREMENTS,
                        requirement.name,
                        true
                      )
                    }
                  />
                );
              })}

            {selectedFoods &&
              data.foods &&
              selectedFoods.split(",").map(sf => {
                const food = data.foods.foods.find(
                  f => sf.toLowerCase() === f.name.toLowerCase()
                );

                if (!food) {
                  return null;
                }

                return (
                  <Chip
                    key={food.id}
                    color="primary"
                    className={classes.filteringChip}
                    label={
                      <Typography style={{ fontSize: "0.7rem" }}>
                        {food.name}
                      </Typography>
                    }
                    onDelete={() =>
                      setQueryString(Dropdowns.FOODS, food.name, true)
                    }
                  />
                );
              })}

            <Grid
              container
              spacing={isMobile ? 2 : 5}
              justifyContent="space-evenly"
              style={{ paddingTop: HEADER_PADDING }}
            >
              <Query<RecipesQuery, RecipesQueryVariables>
                query={recipesGQL}
                variables={{
                  page: currentPage,
                  count: PAGE_SIZE,
                  query,
                  timeMax: time ? time.value : undefined,
                  cuisineId: cuisine ? cuisine.id : undefined,
                  temperatureId: temperature ? temperature.id : undefined,
                  tasteId: taste ? taste.id : undefined,
                  mealTypeId: type ? type.id : undefined,
                  requirementIds: requirements
                    ? requirements.map(r => r.id)
                    : undefined,
                  foodIds: foods ? foods.map(f => f.id) : undefined
                }}
              >
                {({ data, loading }) => (
                  <React.Fragment>
                    {loading || !data || !data.recipes ? (
                      <Loader />
                    ) : data.recipes.recipes.length === 0 ? (
                      <EmptyView>
                        There are currently no recipes having the criteria you
                        selected.
                      </EmptyView>
                    ) : (
                      <React.Fragment>
                        {data.recipes.recipes.map(r => (
                          <Grid
                            item
                            xl={3}
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            key={r.id}
                          >
                            <RecipeTile recipe={r} />
                          </Grid>
                        ))}

                        <br />

                        <Pager
                          currentPage={currentPage}
                          totalItems={data.recipes.total}
                          setCurrentPage={page => setCurrentPage(page)}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </Query>
            </Grid>
          </Grid>
        );
      }}
    </Query>
  );
};
