import { Typography } from "@material-ui/core";
import * as React from "react";
import { HEADER_PADDING } from "../../AppLayout";

interface Props {}

export const EmptyView: React.FC<Props> = ({ children }) => {
  return (
    <div style={{ paddingTop: HEADER_PADDING, textAlign: "center" }}>
      <Typography>
        {children ? (
          children
        ) : (
          <React.Fragment>There seems to be nothing here</React.Fragment>
        )}
      </Typography>
    </div>
  );
};
