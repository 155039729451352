import {
  Avatar,
  Button,
  Grid,
  TextField,
  Theme,
  Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useTheme } from "@material-ui/styles";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { HEADER_PADDING } from "../../../../AppLayout";
import { useStyles } from "./style";

interface Props {
  selectedLetter?: string;
}

export const FoodIndex: React.FC<Props> = ({ selectedLetter }) => {
  const theme = useTheme<Theme>();
  const classes = useStyles(theme);
  const history = useHistory();

  const [foodSearchText, setFoodSearchText] = React.useState<string>();

  const alphabet: string[] = [];
  for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
    alphabet.push(String.fromCharCode(i));
  }

  if (selectedLetter && !alphabet.includes(selectedLetter)) {
    selectedLetter = undefined;
  }

  return (
    <React.Fragment>
      <Typography variant="h3" color="primary" align="center">
        Food Index
      </Typography>

      <div
        style={{
          paddingTop: HEADER_PADDING,
          textAlign: "center"
        }}
      >
        <TextField
          fullWidth
          color="primary"
          label="Enter any food name"
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
            setFoodSearchText(target.value)
          }
          onKeyPress={(e: React.KeyboardEvent) => {
            if (e.charCode === 13) {
              history.push({ search: "?search=" + foodSearchText });
            }
          }}
        />

        <br />
        <Button
          color="primary"
          size="small"
          variant="contained"
          style={{
            marginTop: HEADER_PADDING / 3
          }}
          onClick={() => history.push({ search: "?search=" + foodSearchText })}
        >
          <SearchIcon />
          Search
        </Button>
      </div>

      <Grid
        container
        style={{ paddingTop: HEADER_PADDING }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h4" color="textSecondary" align="center">
            Click any letter
          </Typography>
        </Grid>

        <Grid item xs />

        <Grid
          item
          xs={12}
          style={{ textAlign: "center", paddingTop: HEADER_PADDING / 2 }}
        >
          {alphabet.map((l, i) => (
            <Link
              key={i}
              to={{ pathname: "/food", search: `?letter=${l}` }}
              style={{ textDecoration: "none" }}
            >
              <Avatar
                className={`${classes.letter} ${
                  selectedLetter === l ? classes.selected : ""
                }`}
              >
                {l}
              </Avatar>
            </Link>
          ))}
        </Grid>

        <Grid item xs />
      </Grid>
    </React.Fragment>
  );
};
